<template>
  <a-modal
    width="70%"
    :visible="visible"
    :dialogStyle="dialogStyle"
    dialogClass="dialog"
    :centered="true"
    @cancel="handleCancel"
    :footer="null"
  >
    <div slot="title" class="flex items-center">
      <span class="text-white m-r-sm">报警详情</span>
      <span
        class="report-handler pointer"
        @click="misReport"
        v-if="currentDetail.misReport"
        >误报</span
      >
    </div>
    <div class="flex full-height" ref="conDom">
      <div class="fl full-height" ref="leftDom">
        <div class="on-con relative p-md full-width full-height">
          <span class="cor absolute t-lf"></span>
          <span class="cor absolute t-rf"></span>
          <span class="cor absolute b-lf"></span>
          <span class="cor absolute b-rf"></span>
          <div class="con-main full-height">
            <div class="m-b-sm">
              <span class="text-white m-b-xs block">{{
                cameraDetail.eventDetail || "--"
              }}</span>
              <span class="text-lightgray">{{
                cameraDetail.cameraNum || "--"
              }}</span>
            </div>
            <div class="flex m-b-sm">
              <span class="text-lightblue wd56 text-right m-r-xs"
                >报警事件</span
              >
              <template>
                <span class="flex-one text-white" v-show="!isEdit">{{
                  cameraDetail.eventTypeName || "--"
                }}</span>
                <a-input
                  v-show="isEdit"
                  class="flex-one"
                  v-model="eventTypeName"
                />
              </template>
            </div>

            <div class="flex m-b-sm">
              <span class="text-lightblue wd56 text-right m-r-xs"
                >上报时间</span
              >
              <span class="flex-one text-white">{{
                cameraDetail.detectTime || "--"
              }}</span>
            </div>

            <div class="flex m-b-sm">
              <span class="text-lightblue wd56 text-right m-r-xs"
                >发生路线</span
              >
              <span
                class="flex-one text-white ellipsis"
                :title="getRoad(currentDetail)"
                >{{ getRoad(currentDetail) || "--" }}</span
              >
            </div>

            <div class="flex m-b-sm">
              <span class="text-lightblue wd56 text-right m-r-xs">桩号</span>
              <span class="flex-one text-white">{{
                currentDetail.pileNum || "--"
              }}</span>
            </div>
            <div class="flex m-b-sm">
              <span class="text-lightblue wd56 text-right m-r-xs">方向</span>
              <span class="flex-one text-white">{{
                currentDetail.directionStr || "--"
              }}</span>
            </div>
            <div class="flex m-b-sm">
              <span class="text-lightblue wd56 text-right m-r-xs"
                >管辖单位</span
              >
              <span class="flex-one text-white">{{
                currentDetail.organizationName || "--"
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-one full-height m-l-sm">
        <div class="hd flex justify-between m-b-sm" ref="hdDom">
          <div class="fl-menu flex items-center">
            <span
              class="pointer"
              v-for="(item, index) in menuList"
              :class="{ current: item.id === activeMenu }"
              :key="index"
              @click="tabClick(item)"
              >{{ item.name }}</span
            >
          </div>
          <div class="flex items-center" v-if="type === 'map'">
            <a-button
              type="primary"
              class="m-r-xs"
              @click="prevHandle"
              :disabled="currentIndex === 0"
              >上一个</a-button
            >
            <a-button
              type="primary"
              @click="nextHandle"
              :disabled="getNextDisabled"
              >下一个</a-button
            >
            <span class="text-lightblue m-r-sm" v-if="false"
              >关联摄像机：<span class="text-white">{{
                cameraCount
              }}</span></span
            >
            <a-select
              v-model="selectCurCamera"
              style="width: 120px"
              placeholder="请选择摄像机"
              @change="selectCamera"
              v-if="false"
            >
              <a-select-option
                v-for="item in cameraListNearby"
                :key="item.cameraId"
                :value="item.cameraNum"
              >
                {{ item.khPile }}
              </a-select-option>
            </a-select>
          </div>
        </div>
        <!-- <div ref="rightDom" class="right-dom">
          <div v-if="activeMenu === 0"></div>
          <div v-else-if="activeMenu === 1" class="full-height">
            <div class="full-height flex items-center justify-center">
              <img :src="getImg" v-if="getImg" />
            </div>
          </div>
          <div v-else-if="activeMenu === 2" class="full-height">
            <split-screen ref="splitScreenFull" :size="1" :showClose="false" />
          </div>
        </div> -->

        <div
          ref="rightDom"
          class="right-dom on-con relative full-width ht-con"
          v-if="!confirmLoading"
        >
          <span class="cor absolute t-lf"></span>
          <span class="cor absolute t-rf"></span>
          <span class="cor absolute b-lf"></span>
          <span class="cor absolute b-rf"></span>
          <div v-if="activeMenu === 0"></div>
          <div v-else-if="activeMenu === 1" class="full-height">
            <div class="full-height items-center">
              <div class="flex" style="width: 100%">
                <div style="width: 50%" @click="switchVideo()">
                  <span class="title-name">录像查看</span>
                  <div
                    v-if="cameraDetail.videoList.length > 0"
                    class="switch-box"
                    :class="{ active: !switchModel }"
                    style="height: 154px"
                  >
                    <!-- <split-screen
                      ref="splitScreenFull"
                      :size="1"
                      :showClose="false"
                    /> -->
                    <!-- :id="`flvFxtend-${flvFxtendId}`" -->
                    <flv-player2
                      ref="videoPlay"
                      video-type="flv"
                      :indexs="99911199"
                      :control="false"
                    ></flv-player2>
                  </div>
                  <div class="noData" :class="{ active: !switchModel }" v-else>
                    <img src="~@/assets/AI/noData.png" alt="暂无数据" />
                    <p>暂无数据</p>
                  </div>
                </div>
                <div style="width: 50%" @click="switchPic()">
                  <span class="title-name">图片查看</span>
                  <div
                    v-if="cameraDetail.pictureList.length > 0"
                    style="height: 154px"
                    class="switch-box"
                    :class="{ active: switchModel }"
                  >
                    <img class="first-img" :src="firstImg" alt="" />
                  </div>
                  <div class="noData" :class="{ active: switchModel }" v-else>
                    <img src="~@/assets/AI/noData.png" alt="暂无数据" />
                    <p>暂无数据</p>
                  </div>
                </div>
              </div>
              <div class="clear"></div>
              <span class="evidence">事件证据</span>
              <div class="bot-box">
                <!-- v-if="
                  currentDetail.pictureList.length > 0 &&
                  currentDetail.videoList.length > 0
                " -->
                <div v-show="!switchModel">
                  <div class="flex" v-if="cameraDetail.videoList.length > 0">
                    <evidence-video
                      ref="evidenceVideoList"
                      :dataList="cameraDetail.videoList"
                      :camearId="cameraDetail.cameraId"
                      @getVideoUrl="getVideoUrl"
                    />
                  </div>
                  <div class="noData2" v-else>
                    <img src="~@/assets/AI/noData.png" alt="暂无数据" />
                    <p>暂无数据</p>
                  </div>
                </div>
                <div v-show="switchModel">
                  <div
                    class="pic-list"
                    v-if="cameraDetail.pictureList.length > 0"
                  >
                    <img
                      src="~@/assets/AI/left-btn.png"
                      alt="左侧按钮"
                      @click="leftClick()"
                      class="left-btn"
                    />
                    <img
                      src="~@/assets/AI/right-btn.png"
                      alt="右侧按钮"
                      @click="rightClick()"
                      class="right-btn"
                    />
                    <div
                      v-for="(i, index) in cameraDetail.pictureList"
                      :key="index"
                      class="pic-box"
                    >
                      <img
                        :class="{ active: index === selectedItemIndex }"
                        class="pic-info"
                        @click="selectPic(i, index)"
                        :src="i.url"
                        alt="录像截图"
                      />
                    </div>
                  </div>
                  <div class="noData2" v-else>
                    <img src="~@/assets/AI/noData.png" alt="暂无数据" />
                    <p>暂无数据</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="activeMenu === 2" class="full-height">
            <!-- <split-screen ref="splitScreenFull" :size="1" :showClose="false" /> -->
            <flv-player
              ref="flvVideo"
              video-type="flv"
              :control="false"
            ></flv-player>
          </div>
        </div>
        <loading
          class="flvplayer-loading"
          loading-status="loading"
          message="正在加载中..."
          v-if="confirmLoading"
        ></loading>
      </div>
    </div>
  </a-modal>
</template>

<script>
// import SplitScreen from "@/components/SplitScreen";
import evidenceVideo from "@/components/evidenceVideo";
import Loading from "@/components/module/Loading";
import FlvPlayer2 from "@/components/module/camera/FlvPlayer2";
import FlvPlayer from "@/components/module/camera/FlvPlayer";
const menuList = [
  {
    id: 1,
    name: "事件证据",
  },
  {
    id: 2,
    name: "当前画面",
  },
];
export default {
  name: "EventAlarmDetail",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    cameraDetailId: {
      type: String,
      default: "",
    },
    cameraDetail: {
      type: Object,
      default() {
        return {};
      },
    },
    sourceType: {
      type: String,
      default: "hisId",
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isEdit: false,
      confirmLoading: true,
      plainValue: 0,
      activeMenu: 1,
      menuList: menuList,
      inputText: "",
      eventSelect: [],
      plainOptions: [
        { label: "一般", value: "0" },
        { label: "严重", value: "1" },
        { label: "特严重", value: "2" },
      ],
      cameraCount: 0,
      cameraListNearby: [],
      selectCurCamera: undefined,
      eventTypeName: "",
      dialogStyle: { maxWidth: "1000px", maxHeight: "550px", height: "80%" },
      switchModel: false,
      currentEventDetailList: [],
      currentIndex: 0,
      flvFxtendId: this.cameraDetail.cameraId,
      player: null, // 用来存储当前 video
      selectedItemIndex: -1,
    };
  },
  computed: {
    getEventLevel() {
      return this.plainValue === "0"
        ? "一般"
        : this.plainValue === "1"
        ? "严重"
        : this.plainValue === "2"
        ? "特严重"
        : "";
    },
    getNextDisabled() {
      return (
        (this.currentIndex === this.currentEventDetailList.length - 1 &&
          this.currentEventDetailList.length > 0) ||
        this.currentEventDetailList.length <= 0
      );
    },
    currentDetail() {
      return this.currentEventDetailList[this.currentIndex] || {};
    },
    getMediaList() {
      let currentMedia = this.currentDetail["mediaList"];
      return currentMedia ? JSON.parse(currentMedia) : [];
    },
    getImg() {
      return this.getMediaList.length ? this.getMediaList[0]["src"] : "";
    },
  },
  mounted() {
    setTimeout(() => {
      // "https://jxsjfx.transportcloud.cn/video_res/testvideo/2024-06-17/5758_588e343066cf54ec3db5132231df7d68/5758_1718582265.mp4?server=aHR0cDovLzM2LjY0LjQuMjA6ODg4OA%3D%3D",
      this.$refs.videoPlay?.flv_Play(
        this.cameraDetail.videoList[1].url,
        this.cameraDetail,
        "mp4"
      );
      console.log("this.activeMenu", this.activeMenu, this.$refs.videoPlay);
      this.$refs.evidenceVideoList?.getUrl(this.cameraDetail.videoList);
    }, 3000);

    this.selectedItemIndex = -1;
    // this._getEventAlarmDetail(newValue);
    setTimeout(() => {
      this.confirmLoading = false;
      this.firstImg = this.cameraDetail.pictureList[0].url;
    }, 1000);
    this.currentEventDetailList = this.cameraDetail;
  },
  watch: {
    cameraDetailId(newValue, oldValue) {
      // this.flvFxtendId = this.cameraDetail.cameraId
      this.firstImg = this.cameraDetail.pictureList[0].url;
      if (newValue && newValue != oldValue) {
        this.selectedItemIndex = -1;
        // this._getEventAlarmDetail(newValue);
        setTimeout(() => {
          this.confirmLoading = false;
        }, 1000);
        this.currentEventDetailList = this.cameraDetail;
      }
    },
    // getMediaList(nv) {
    //   this.menuList = nv.length
    //     ? menuList
    //     : [
    //         {
    //           id: 2,
    //           name: "当前画面",
    //         },
    //       ];
    //   this.activeMenu = this.menuList[0].id;
    //   console.log("this.activeMenu", this.activeMenu);
    // },
    activeMenu(nv) {
      if (nv == 1) {
        this.$nextTick(() => {
          // setTimeout(() => {
          this.$refs.videoPlay?.flv_Play(
            this.cameraDetail.videoList[1].url,
            this.cameraDetail,
            "mp4"
          );
          // }, 1000);
        });
      }
    },
    currentDetail: {
      handler(nv) {
        this.openStream(nv);
        console.log("nv", nv);
      },
      deep: true,
    },
    /* cameraDetail: {
      handler(newValue) {
        this.setDefault(newValue);
      },
      deep: true,
    }, */
  },
  created() {
    this.activeMenu = this.menuList[0].id;
  },
  methods: {
    getVideoUrl(val) {
      console.log("获取点击的录像信息", val);
      // this.cameraUrl = val.url;
      // this.$nextTick(() => {
      //   this.$refs.splitScreenFull &&
      //     this.$refs.splitScreenFull.flv_Play(val.url, {});
      // });
      this.$refs.videoPlay?.flv_Play(val.url, val, "mp4");
    },
    getVideoUrlToPlay() {
      let obj = {
        cameraNum: this.cameraDetail.cameraNum, //'e5228b11-f64a-4669-93e2-8dc4b5d09a32', //record.data.cameraName,
        mediatype: "flvUrl",
        videoType: "0",
      };
      let objParams = {
        data: obj,
      };
      this.$api.home.getCameraPlayUrl(objParams).then((res) => {
        if (res.code === 200) {
          if (!res.data || !res.data.flv) {
            this.$message.error("视频地址请求失败");
            return false;
          }
          this.videoUrl = res.data.flv; //res.data;
          console.log("this.videoUrl", this.videoUrl);
          this.$refs.flvVideo.flv_Play(this.videoUrl);
        } else {
          this.$message.error("请求失败！");
        }
      });
    },
    openStream(nv) {
      console.log("TABS", nv);
      this.$refs.evidenceVideoList?.getUrl(this.cameraDetail.videoList);
      if (this.activeMenu == 2) {
        this.$nextTick(() => {
          this.getVideoUrlToPlay();
          // this.$refs.splitScreenFull.setCameraList([nv]);
        });
      }
    },
    nextHandle() {
      this.currentIndex++;
    },
    prevHandle() {
      this.currentIndex--;
    },
    getRoad(currentDetail) {
      return `${currentDetail.roadCode} ${currentDetail.roadName}`;
    },
    _getEventAlarmDetail(eventCqId) {
      let data = {
        [this.sourceType]: eventCqId,
      };

      this.$api.aiCenterY.queryEventResultListByCondition(data).then((res) => {
        if (res.code === 200) {
          // // console.error(res);
          // this.cameraCount = res.data['关联摄像机数'];
          // this.cameraListNearby = res.data['摄像机列表'];
          this.currentEventDetailList = res.data.map((item) => {
            item.misReport = true;
            return item;
          });
          /* this.$nextTick(() => {
            this.$refs.splitScreenFull.setCameraList([
              this.currentEventDetailList[0],
            ]);
          }); */
        }
      });
    },
    handleCancel() {
      // this.activeMenu === 2 && this.$refs.splitScreenFull.destroyVideo(1);
      this.activeMenu = 0;
      this.currentIndex = 0;
      this.$emit("close");
    },
    editEvent() {
      this.isEdit = true;
    },
    submitHandle() {
      this.isEdit = false;
      //修改数据
      let obj = {
        eventTypeName: this.eventTypeName,
        eventLevel: this.plainValue,
        eventSummary: this.inputText,
      };
      let params = {
        eventLevel: obj.eventLevel,
        eventSummary: obj.eventSummary,
        eventTypeName: obj.eventTypeName,
        id: this.cameraDetailId,
      };
      this.$api.aiCenterY.modifyEventAlarm(params).then((res) => {
        if (res.code === 200) {
          this.$message.success("修改成功!");
          this.setOrigin(obj);
        } else {
          this.$message.error("修改失败!");
        }
      });
    },
    cancelHandle() {
      /* this.isEdit = false;
      //重置为上次数据
      this.eventTypeName = this.originName;
      this.plainValue = this.originPlainValue;
      this.inputText = this.originInputText; */
      this.activeMenu = 0;
    },
    handleChange(value) {
      this.eventSelect = value;
    },
    onChangeRadio(e) {
      this.plainValue = e.target.value;
    },
    tabClick(item) {
      this.activeMenu = item.id;
      console.log("haha", this.currentDetail, this.activeMenu);
      this.openStream(this.cameraDetail);
    },
    selectCamera(e) {
      this.selectCurCamera = e;
      // let cameraList = this.cameraListNearby.filter(
      //   (item) => item.cameraNum === this.selectCurCamera
      // );
      // console.error('hah', this.selectCurCamera, cameraList);
      this.$nextTick(() => {
        // this.$refs.splitScreenFull.setCameraList(cameraList);
      });
    },
    /* setDefault(newValue) {
      this.eventTypeName = newValue.eventTypeName;
      this.plainValue = newValue.eventLevel;
      this.inputText = newValue.eventSummary;
      this.setOrigin(newValue);
      this.$nextTick(() => {
        this.$refs.splitScreenFull.setCameraList([this.cameraDetail]);
      });
    }, */
    setOrigin(newValue) {
      this.originName = newValue.eventTypeName;
      this.originPlainValue = newValue.eventLevel;
      this.originInputText = newValue.eventSummary;
    },
    misReport() {
      if (!this.hasPermission(109212111010)) {
        this.permissionWarning();
        return;
      }
      let _this = this;
      this.$confirm({
        content: "确认误报将删除此条报警信息，确认该报警为误报吗？",
        zIndex: 2147483649,
        onOk() {
          _this.misReportHandle();
        },
        onCancel() {},
      });
    },
    misReportHandle() {
      let params = {
        id: this.currentDetail.hisId,
      };
      let objParams = {
        data: params,
        instructionsDel: {
          module: "路线报警统计",
          page: "查看报警",
          feature: "误报",
          description: this.currentDetail.eventName,
        },
      };
      this.$api.aiCenterY.misinformation(objParams).then((res) => {
        if (res.code === 200) {
          this.$message.success("提交成功!");
          this.currentDetail.misReport = false;
          this.$emit("misReportHandle");
        } else {
          this.$message.error("提交失败!");
        }
      });
    },
    // 切换
    switchVideo(e) {
      console.log("1222", e);
      this.switchModel = false;
    },
    selectPic(val, index) {
      console.log("选择的图片", val);
      this.firstImg = val.url;
      this.selectedItemIndex = index;
    },
    // 切换图片
    switchPic(e) {
      console.log("1222", e);
      this.switchModel = true;
    },
    leftClick() {
      const gallery = document.querySelector(".pic-list");
      gallery.scrollBy(-175, 0);
    },
    rightClick() {
      const gallery = document.querySelector(".pic-list");
      gallery.scrollBy(175, 0);
      console.log("gallery", gallery);
    },
  },
  components: {
    // SplitScreen,
    Loading,
    FlvPlayer2,
    FlvPlayer,
    evidenceVideo,
  },
};
</script>

<style scoped lang="less">
.fl {
  width: 38%;
}
.wd56 {
  width: 68px;
}
.right-dom {
  // height: calc(100% - 40px);
  width: 579px;
  height: 413px;
  background: linear-gradient(
    146deg,
    rgba(102, 153, 254, 0.2),
    rgba(53, 82, 236, 0) 100%
  );
  border: 1px solid #0178f3;
  .video-url {
    display: block;
    width: 175px;
    height: 98px;
    background: red;
  }
  .title-name {
    width: 100%;
    display: block;
    text-align: center;
    margin: 15px 0 2px 0;
    background-image: url("~@/assets/AI/title-short.png");
    background-repeat: no-repeat;
    background-size: 100%;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    font-family: Inter, Inter-400;
    font-weight: 400;
    color: #ffffff;
  }
  .evidence {
    width: 100%;
    display: block;
    text-align: center;
    background-image: url("~@/assets/AI/title-long.png");
    background-repeat: no-repeat;
    background-size: 100%;
    line-height: 24px;
    height: 24px;
    margin: 12px 0 5px 0;
    font-size: 12px;
    font-family: Inter, Inter-400;
    font-weight: 400;
    color: #ffffff;
  }
  .bot-box {
    width: 577px;
    height: 138px;
    margin-left: 6px;
    position: relative;
    background: linear-gradient(
      90deg,
      rgba(8, 92, 255, 0),
      rgba(78, 138, 252, 0.6) 51%,
      rgba(8, 107, 255, 0) 100%
    );
  }
  .switch-box {
    width: 273px;
    height: 154px;
    margin: 0 4px;
    .first-img {
      width: 100%;
      height: 100%;
    }
  }
  .noData {
    width: 100%;
    height: 157px;
    text-align: center;
    img {
      margin: 15% 33% 0 33%;
      width: 100px;
      height: 60px;
      margin-bottom: 10px;
    }
  }
}
.noData2 {
  text-align: center;
  img {
    margin: 3% 33%;
    width: 100px;
    height: 60px;
  }
}
::v-deep .ant-modal-content {
  height: 100%;
}
::v-deep .ant-modal-body {
  height: calc(100% - 50px);
}
.pic-list {
  overflow: hidden;
  display: flex;
  width: 560px;
  padding: 20px 2px;
  img {
    width: 18px;
    height: 138px;
  }
  .left-btn {
    position: absolute;
    top: 0;
    left: -10px;
  }
  .right-btn {
    position: absolute;
    top: 0;
    right: 2px;
  }
  .pic-box {
    width: 175px;
    height: 98px;
    margin: 0 5px;
    background: #0e2132;
    .pic-info {
      width: 175px;
      height: 98px;
    }
  }
}
.active {
  border: 2px solid rgba(212, 34, 10);
}
</style>

