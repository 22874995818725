<template>
  <div class="flvPlayerWrap relative">
    <div v-show="control">
      <div class="play-before" v-if="flvManualPlay">
        <div class="play-control" @click="flv_play"></div>
      </div>
      <div class="play-pause" v-if="!flvManualPlay">
        <div class="play-control" @click="flv_pause"></div>
      </div>
    </div>
    <!-- {{ indexs }} -->
    <!-- {{ urls.url }} -->
    <!-- <video ref="`videoEle`" :style="adjustVideo" id="videoEle" muted></video> -->
    <video
      :ref="'videoPlay' + indexs"
      :style="adjustVideo"
      :id="'videoPlay' + indexs"
      muted
    ></video>
    <!-- <div class="absolute full-width full-height l0 t0">
      <span v-show="showError">
        播放有错!
      </span>
    </div> -->
    <loading
      class="flvplayer-loading"
      loading-status="loading"
      message="正在加载中..."
      :showLoad="showMin"
      v-if="flvLoading && !flvLongLoading"
    ></loading>
    <errorLoading
      class="flvplayer-loading"
      :showLoad="showMin"
      v-if="flvErrorLoading && !flvLongLoading"
    ></errorLoading>
    <longLoading
      class="flvplayer-loading"
      :showLoad="showMin"
      v-if="flvLongLoading"
    ></longLoading>
    <div class="canvasImg">
      <!-- <p class="close" @click="closeCanvas">X</p> -->
      <canvas id="canvas2" v-show="showCanvas"></canvas>
    </div>
  </div>
</template>

<script>
import FlvJs from "flv.js";
import Loading from "@/components/module/Loading";
import errorLoading from "@/components/module/errorLoading";
import longLoading from "@/components/module/longLoading";

import { mapActions } from "vuex";
// eslint-disable-next-line no-unused-vars
function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}
export default {
  name: "FlvPlayer",
  props: {
    showMin: {
      type: Boolean,
      default: false,
    },
    control: {
      type: Boolean,
      default: true,
    },
    indexs: {
      type: Number,
      default: 0,
    },
    urls: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    Loading,
    errorLoading,
    longLoading,
  },
  data() {
    return {
      flvPlayer: null,
      flvLoading: false,
      flvErrorLoading: false,
      flvLongLoading: false,
      flvManualPlay: true,
      flvPause: false,
      player_error_types: {
        NetworkError: "Errors related to the network",
        MediaError:
          "Errors related to the media (format error, decode issue, etc)",
        OtherError: "Any other unspecified error",
      },
      cameraInfo: null,
      showCanvas: false,
      adjustVideo: {},
      // showError: false,
      timeCount: 0,
      // levelCount:0
    };
  },
  mounted() {
    window.flvPlayer_vue = this;
    //console.info(FlvJs,FlvJs.isSupported());
    /*
    ERROR: 'error',
    LOADING_COMPLETE: 'loading_complete',
    RECOVERED_EARLY_EOF: 'recovered_early_eof',
    MEDIA_INFO: 'media_info',
    METADATA_ARRIVED: 'metadata_arrived',
    SCRIPTDATA_ARRIVED: 'scriptdata_arrived',
    STATISTICS_INFO: 'statistics_info'
    */
  },
  methods: {
    ...mapActions(["getCameraPlayUrl"]),
    // 调节视频 type:1:亮度 2:对比度 3:锐度
    adjust(adjustBright, adjustContrast, adjustAcutance) {
      this.adjustVideo = {
        "-webkit-filter": `brightness(${adjustBright}%) contrast(${adjustContrast}%) blur(${adjustAcutance}px)`,
      };
    },
    //视频手动截图
    flv_play_status_reset() {
      // console.log('flv_play_status_reset()')
      this.flvPause = true;
      this.flvLoading = false;
      this.flvLongLoading = false;
      this.flvErrorLoading = false;
      this.flvManualPlay = true;
    },
    flv_Play(sourceUrl, data, type, options = { isPlayByUser: true }) {
      console.log("sourceUrl, data", sourceUrl, data);
      // console.clear()
      // console.log('新视频播放')
      if (options?.isPlayByUser) {
        // console.log('重置 正在加载中')
        this.flvLoading = true;
        this.flvLongLoading = false;
        this.flvErrorLoading = false;
      }
      //console.info("FlvJs  ", FlvJs,this.$refs.videoEle);
      // sourceUrl = 'https://cqplay.gandongyun.com/live/e5228b11-f64a-4669-93e2-8dc4b5d09a32.flv?txSecret=be7f230e85447941816bbb70f5e4474e&txTime=60FA420D';

      this.cameraInfo = data;
      // console.log('摄像机详情', this.cameraInfo)
      // this.showError = false
      if (!FlvJs.isSupported()) {
        let check1 = !!window.MediaSource;
        let check2 = false;
        if (
          check1 &&
          window.MediaSource.isTypeSupported(
            'video/mp4; codecs="avc1.42E01E,mp4a.40.2"'
          )
        ) {
          check2 = true;
        }
        alert(
          "你的设备不支持flvjs。其中window.MediaSource：" +
            check1 +
            "。\n window.MediaSource.isTypeSupported('video/mp4; codecs=\"avc1.42E01E,mp4a.40.2\"'):" +
            check2
        );
      }
      try {
        // let videoElement = this.$refs.videoEle; // document.getElementById("videoEle");
        let videoElement = document.getElementById(`videoPlay${this.indexs}`);

        if (typeof this.flvPlayer !== "undefined") {
          if (this.flvPlayer != null) {
            this.flvPlayer.pause();
            this.flvPlayer.unload();
            this.flvPlayer.detachMediaElement();
            this.flvPlayer.destroy();
            this.flvPlayer = null;
          }
        }
        this.flvPlayer = FlvJs.createPlayer(
          {
            type: "mp4",
            isLive: true, // 直播
            hasVideo: true, // 视频
            hasAudio: false, // 音频
            autoplay: true, // 自动播放
            controls: true, // 控件
            url: sourceUrl, // 视频地址
            withCredentials: false, //
            cors: true,
            timestampBase: 0,
          },
          {
            /*enableWorker: false,
                      lazyLoadMaxDuration: 3 * 60,
                      seekType: 'range',*/

            //seekType: 'range', // 搜索
            enableWorker: true, // 分离线程加载数据
            enableStashBuffer: false, //禁用IO存储缓冲区,解决卡顿的根本问题
            stashInitialSize: 2044, //指示IO存储缓冲区初始大小,默认是 384kb*/
            //lazyLoad: true,// 数据缓存量足够情况下停止加载。默认打开，这里会阻止实时加载。
            //lazyLoadMaxDuration: 60,// 缓存秒数
            //lazyLoadRecoverDuration: 20,// 更新覆盖描述
            deferLoadAfterSourceOpen: false, // 切换浏览器选项卡之后停止加载，false取消限制
            /**看效果 */
            //autoCleanupSourceBuffer: true,
            //autoCleanupMaxBackwardDuration: 60,
            //autoCleanupMinBackwardDuration: 30,
            //statisticsInfoReportInterval: 1,
            //fixAudioTimestampGap: false,
            /**看效果 */
          }
        );

        this.flvPlayer.attachMediaElement(videoElement);
        //console.info(this.flvPlayer,this.flvPlayer.play,sourceUrl);
        // eslint-disable-next-line no-unused-vars
        this.flvPlayer.on("error", (a, b, c) => {
          // console.log('flvPlayer.on.error()')
          // console.log("=========================== player ERROR", a, b, c);
          //this.$message.error("播放错误["+c.code+"]:"+a+" "+b+" "+c.msg);

          // this.$notification.error({
          //   message: "播放错误(" + a + ")",
          //   description:"错误原因："+ b + "，" + this.player_error_types[a] +"，错误提示："+c.msg,
          //   duration: 10,
          //   class: "player-notification",
          // });
          // this.showError = true
          this.flvLoading = false;
          this.flvLongLoading = true;
          this.flvErrorLoading = false;
          // this.$notification.error({
          //   message: '播放异常',
          //   description: '',
          //   placement: 'bottomRight',
          //   duration: 2,
          //   class:
          //     'player-notification player-notification-bottom'
          // })

          this.flvPause = true;
          this.flvPlayer.unload();
          this.flvManualPlay = true;
          //断线重连
          // if (this.cameraInfo.reconnectFlag) {
          this.reconnect();
          // this.cameraInfo.reconnectFlag = false
          // }
        });
        /*this.flvPlayer.on("loading_complete", (a, b, c) => {
          this.flvLoading = false;
          console.log("=========================== player LOADING_COMPLETE", a, b, c);
        });*/
        this.flvPlayer.on("statistics_info", (info) => {
          // console.log('statistics_info', info.speed, info)

          if (info && info.speed) {
            // console.log('info.speed 开始播放')
            clearTimeout(this.longTime);
            this.flvLoading = false;
            this.flvLongLoading = false;
            this.timeCount = 0;
          } else {
            /* eslint no-unreachable:off */
            // console.log('speed为0')

            return;
            this.timeCount++;
            if (this.timeCount === 8) {
              this.timeCount = 0;
              this.cameraInfo.levelCount++;
              this.reconnect();
            }
          }
        });
        if (!options || !options?.manualPlay) {
          this.flv_play();
        }
      } catch (e) {
        // )
      }
    },
    createPlayer(videoElement, sourceUrl) {
      this.flvPlayer = FlvJs.createPlayer(
        {
          type: "flv",
          isLive: true, // 直播
          hasVideo: true, // 视频
          hasAudio: false, // 音频
          //autoplay        : true, // 自动播放
          //controls        : true, // 控件
          url: sourceUrl, // 视频地址
          withCredentials: false, //
          cors: true,
          timestampBase: 0,
        },
        {
          /*enableWorker: false,
                      lazyLoadMaxDuration: 3 * 60,
                      seekType: 'range',*/

          //seekType: 'range', // 搜索
          enableWorker: true, // 分离线程加载数据
          enableStashBuffer: false, //禁用IO存储缓冲区,解决卡顿的根本问题
          stashInitialSize: 2, //指示IO存储缓冲区初始大小,默认是 384kb*/
          //lazyLoad: true,// 数据缓存量足够情况下停止加载。默认打开，这里会阻止实时加载。
          //lazyLoadMaxDuration: 60,// 缓存秒数
          //lazyLoadRecoverDuration: 20,// 更新覆盖描述
          deferLoadAfterSourceOpen: false, // 切换浏览器选项卡之后停止加载，false取消限制
          //autoCleanupSourceBuffer: true,
          //autoCleanupMaxBackwardDuration: 60,
          //autoCleanupMinBackwardDuration: 30,
          //statisticsInfoReportInterval: 1,
          //fixAudioTimestampGap: false,
        }
      );

      this.flvPlayer.attachMediaElement(videoElement);
    },
    reconnect() {
      this.reconnectTimer && clearTimeout(this.reconnectTimer);
      this.reconnectTimer = setTimeout(() => {
        // console.log('info2', '3s后播放器内部断线重连')
        // eslint-disable-next-line no-unused-vars
        // console.log('hha2', this.cameraInfo)
        let obj = {
          data: this.cameraInfo,
        };
        this.$api.home.getCameraPlayUrl(obj).then((res) => {
          this.flv_Play(res.data.flv, this.cameraInfo, { isPlayByUser: false });
          this.timeCount = 0;
        });
      }, 1000);
    },
    flv_play() {
      console.log(
        "222 this.flvLoading",
        this.flvLoading,
        "flvLongLoading:",
        this.flvLongLoading,
        "flvErrorLoading:",
        this.flvErrorLoading
      );
      this.playTimer && clearTimeout(this.playTimer, this.longTime);
      this.flvPause = false;
      this.flvLoading = false;
      this.flvManualPlay = false;
      console.log(
        "333this.flvLoading",
        this.flvLoading,
        "flvLongLoading:",
        this.flvLongLoading,
        "flvErrorLoading:",
        this.flvErrorLoading
      );

      if (this.flvPlayer) {
        this.flvPlayer.load();
        this.flvPlayer.play().then(() => {
          clearTimeout(this.longTime);
        });
        if (this.flvLoading) {
          this.longTime = setTimeout(() => {
            this.flvLongLoading = true;
            this.flvLoading = false;
          }, 10000);
        }
      } else {
        this.playTimer = setTimeout(() => {
          this.flvPlayer.load();
          this.flvLongLoading = false;
          this.errorLoading = false;
          this.flvLoading = false;
          this.flvPlayer.play();
        }, 300);
      }

      /*  this.playTimer = setTimeout(() => {
        this.flvPlayer.load();
        this.flvPlayer.play();
      }, 600); */
    },
    flv_pause() {
      this.flvPause = !this.flvPause;
      if (this.flvPause) {
        this.flv_play_status_reset();
        this.flvPlayer.pause();
        this.flvPlayer.unload();
      } else {
        this.getCameraPlayUrl(this.cameraInfo).then((res) => {
          this.flv_Play(res.data.url, this.cameraInfo, { isPlayByUser: false });
        });
        // this.flv_play();
      }
    },

    /**
     * 播放器销毁
     */
    flv_destroy() {
      // console.log('销毁', this.flvPlayer)
      if (this.flvPlayer) {
        // console.error('papasxy');
        this.flv_play_status_reset();
        /*this.flvPlayer.off("error");
        this.flvPlayer.off("statistics_info");*/
        this.flvPlayer.pause();
        this.flvPlayer.unload();
        this.flvPlayer.detachMediaElement();
        this.flvPlayer.destroy();
        this.flvPlayer = null;
        // console.log('销毁le', this.flvPlayer)
      }
    },
    flv_errorPlay() {
      this.flvErrorLoading = true;
    },
    flv_longPlay() {
      this.longLoading = false;
    },
    /**
     * 播放器全屏
     */
    flv_fullScreen() {
      let ele = this.$refs.videoEle; //document.getElementById("videoEle");
      if (ele.requestFullscreen) {
        ele.requestFullscreen();
      } else if (ele.mozRequestFullScreen) {
        ele.mozRequestFullScreen();
      } else if (ele.webkitRequestFullScreen) {
        ele.webkitRequestFullScreen();
      }
    },
    // loadError(e) {
    //   console.info('flv loadError', e)
    // }
  },
  destroyed() {
    this.reconnectTimer && clearTimeout(this.reconnectTimer);
    this.playTimer && clearTimeout(this.playTimer);
  },

  getEntryTiming(entry) {
    var t = entry;

    var times = {};

    // 重定向的时间
    times.redirect = t.redirectEnd - t.redirectStart;

    // DNS 查询时间
    times.lookupDomain = t.domainLookupEnd - t.domainLookupStart;

    // 内容加载完成的时间
    times.request = t.responseEnd - t.requestStart;

    // TCP 建立连接完成握手的时间
    times.connect = t.connectEnd - t.connectStart;

    // 挂载 entry 返回
    times.name = entry.name;

    times.entryType = entry.entryType;

    times.initiatorType = entry.initiatorType;

    times.duration = entry.duration;
    // console.log(times, 'times')

    return times;
  },
};
</script>

<style lang="less" scoped>
.flvPlayerWrap {
  width: 100%;
  height: 100%;
  position: relative;
  video {
    width: 100%;
    height: 100%;
    background: #000;
    z-index: 10;
    object-fit: fill;
  }
  .play-before,
  .play-pause,
  .flvplayer-loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(13, 45, 74, 0.6);
    z-index: 11;
  }
  .play-before {
    .play-control {
      width: 40%;
      height: 40%;
      max-width: 40px;
      max-height: 40px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-repeat: no-repeat;
      background-size: auto 100%;
      background-position: center center;
      // background-image: url('../../../assets/images/dashboard/play-btn2x.png');
      background-image: url("../../../assets/dialog/play.png");

      cursor: pointer;
      transition: all 0.3s;
      /*  &:hover {
        width: 70px;
        height: 70px;
      } */
    }
  }
  .play-pause {
    display: none;
    .play-control {
      width: 40%;
      height: 40%;
      max-width: 40px;
      max-height: 40px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-repeat: no-repeat;
      background-size: auto 100%;
      background-position: center center;
      // background-image: url("../../../assets/images/dashboard/pause-btn2x.png");
      background-image: url("../../../assets/dialog/pause.png");
      cursor: pointer;
      transition: all 0.3s;
      /*  &:hover {
        width: 40%;
        height: 40%;
      } */
    }
  }
  /*  .flvplayer-loading {
  } */
  &:hover {
    .play-pause {
      display: block;
    }
  }
}

.ant-notification {
  z-index: 1040;
  right: -10px !important;
  margin: 0px !important;

  .player-notification {
    background: rgba(0, 21, 41, 0.85);
    width: 600px;
    margin-left: -210px;
    box-shadow: 0 0 16px 10px #090c0e;
    color: #c9c9c9;
    border: 1px solid #236da1;
    .ant-notification-notice-message {
      color: #c9c9c9;
    }
    .ant-notification-notice-close {
      color: rgba(255, 255, 255, 0.6);
    }
  }

  .player-notification-bottom {
    width: 350px;
    padding: 10px 16px !important;
    margin: 0px !important;

    .ant-notification-notice-close {
      top: 13px;
    }
  }
}

/* .ant-notification-notice {
  width: 300px !important;
} */

/*video默认setting按钮*/
video::-internal-media-controls-overflow-button {
  display: none !important;
}
/*播放按钮*/
video::-webkit-media-controls-play-button {
  display: none !important;
}
/* 时间线*/
video::-webkit-media-controls-timeline {
  display: none !important;
}
/* 时间*/
video::-webkit-media-controls-current-time-display {
  display: none !important;
}
video::-webkit-media-controls-time-remaining-display {
  display: none !important;
}
/*video默认aduio音量按钮*/
video::-webkit-media-controls-mute-button {
  display: none !important;
}
video::-webkit-media-controls-toggle-closed-captions-button {
  display: none !important;
}
video::-webkit-media-controls-volume-slider {
  display: none !important;
}
/* .canvasImg {
  .close {
    position: absolute;
    top: 117%;
    right: 10px;
  }
  #canvas2 {
    padding-top: 40px;
  }
} */
</style>
