import Vue from 'vue';
import Vuex from 'vuex';

/*import router from '@/router/router'; */

Vue.use(Vuex);
let vm = new Vue();
/*  */
const state = {
  getCameras: '',
  cameralList: [],
  cameralListTree: [],
  chartData: [],
  chartTotalData: [],
  classify: '',
  amapIns: null,
  selectNode: null,
  botClassify: ['1', '2', '3', '4', '5', '6', '7', '8', '0'],
  botClassifyCopy: ['1', '2', '3', '4', '5', '6', '7', '8', '0'],
  isAll: true,
  onlineStatus: ['0', '1', '2'],
  statusCount: {
    online: 0,
    offline: 0,
    error: 0
  },
  type: 'road',
  blockList: [],
  splitScreenInfo: null,
  addressValue: '',
  lat: '',
  latResult: '',
  addVisible: false,
  latVisible: false,
  searchValue: '',
  hasFullScreen: false,
  maxHeight: 0,
  cameraInfoList: [],
  dragMove: { x1: 0, y1: 0, h1: 0, x2: 0, y2: 0, h2: 0 },
  clickCameraList: [],
  defaultZoom: 4,
  searchCameraId: null, //搜索cameraId
  searchOrganizationId: '', //搜索searchOrganizationId
  projectionVideoList: [], //投屏视频
  videoSize: [1], //视频尺寸
  currentVideoIndex: 0,
  homeFolder: [], //文件夹
  faultDetail: [], //故障上报详情
  homeFolderCamera: [], //摄像机
  drawerDom: null,
  currentDrawerCom: null,
  treeDom: null, //树
  searchType: 'camera',
  isSearchBtn: false,
  needStopCamera: {}, //判断停流当前摄像机
  mapClick: false,
  searchOk: false,
  screenActiveIndex: [0],
  treeListWd:320,
  isShowClassify:true, 
  currentScreen:0,//哪个分屏
  routeList:[]
};
const actions = {
  cameraYtControlAction(store, obj) {
    let newObj = JSON.parse(JSON.stringify(obj));
    delete newObj.cameraName;
    let objParams = {
      data: newObj,
      instructionsDel: {
        module: '地图调阅',
        page: '视频调阅',
        feature: '云台控制',
        description: `云台控制: ${obj.cameraName}`
      }
    };
    return Vue.prototype.$api.home.cameraYtControl(objParams).then(data => {
      if (data.code == 409) {
        vm.$message.error(data.message);
        return Promise.reject(data);
      }
      if (data.code !== 200) {
        vm.$message.error('调用云台控制失败! [' + data.code + ']');
        return Promise.reject(data);
      }
      return data;
    });
  },
  // 搜索框下拉菜单数据
  getCameraLists(store, obj) {
    return Vue.prototype.$api.home.getCameraLocal(obj).then(data => {
      store.commit('getCamera', data.data);
      return data;
    });
  },
  /**
   * 获取摄像机列表
   * @param store
   * @param obj
   * @returns {Promise<*>}
   */
  getCameraInfoByList(store, obj) {
    return Vue.prototype.$api.home.getCameraList(obj).then(data => {
      if (data.code !== 200) {
        vm.$message.error(
          '获取摄像机信息失败! [' + data.code + ']: ' + data.message
        );
        return Promise.reject();
      }
      const dataList = data.data.map(item=>{
        item.longAndLatiNew = item.correctLongAndLati?item.correctLongAndLati:item.longAndLati;
        return item;
      })
      store.commit('setCameraInfo', dataList);
      return data;
    });
  },
  // 获取拥堵路段
  getAllRoadLineBlockList(store) {
    return Vue.prototype.$api.home.getAllRoadLineBlock().then(data => {
      if (data.code !== 200) {
        vm.$message.error('获取拥堵路段信息失败');
      }
      let newData = data.data.map(item => {
        item.check = false;
        return item;
      });
      store.commit('setBlockList', newData);
    });
  },
  //获取收藏夹
  getHomeFolder(store) {
    return Vue.prototype.$api.home.getFolder().then(data => {
      if (data.code !== 200) {
        vm.$message.error('获取收藏文件夹失败');
        return;
      }
      let newData = data.data.map(item => {
        if (item.whetherDefaultFolder) {
          item.show = true;
        } else {
          item.show = false;
        }
        return item;
      });
      store.commit('setHomeFolder', newData);
      return data.data;
    });
  },
  //获取故障详情
  getFaultDetail(store) {
    return Vue.prototype.$api.home.getFaultList().then(data => {
      if (data.code !== 200) {
        vm.$message.error('获取故障列表失败');
        return;
      }
      let newData = data.data
      store.commit('setFaultDetail', newData);
      return newData;
    });
  },
  //获取默认展开收藏夹摄像机
  getHomeDefaultCamera({ state, commit, dispatch }) {
    return dispatch('getHomeFolder').then(data => {
      let { folderId: defaultFolderId } = data.filter(
        folder => folder.whetherDefaultFolder === 1
      )[0];
      let params = { folder: defaultFolderId };
      return Vue.prototype.$api.home.getFolderCamera(params).then(data2 => {
        let { homeFolder } = state;
        let newHomeFolderCamera = [...homeFolder];
        newHomeFolderCamera = newHomeFolderCamera.map(item => {
          if (item.folderId === defaultFolderId) {
            item.cameraList = data2.data;
          } else {
            item.cameraList = [];
          }
          return item;
        });
        commit('setHomeFolderCamera', newHomeFolderCamera);
        return data2.data;
      });
    });
  },
    //获取收藏夹所有摄像机
    getHomeAllFolderCamera({
      state,
      commit
    }, params) {
      return Vue.prototype.$api.home.getAllFolderCamera(params).then((data) => {
        if (data.code !== 200) {
          vm.$message.error('获取收藏文件夹摄像机失败')
          return
        }
        let {
          folder: folderId
        } = params
        let {
          homeFolderCamera,
          homeFolder
        } = state
        let newHomeFolderCamera = []
        homeFolder.forEach((folder, index) => {
          if (folder.folderId === folderId) {
            // homeFolderCamera[index].cameraList = data.data;
            Vue.set(homeFolderCamera[index], 'cameraList', data.data)
          }
        })
        newHomeFolderCamera = homeFolderCamera
        // console.log('数据', newHomeFolderCamera);
        commit('setHomeFolderCamera', newHomeFolderCamera)
        return data
      })
    },
  //获取收藏夹摄像机
  getHomeFolderCamera({ state, commit }, params) {
    return Vue.prototype.$api.home.getFolderCamera(params).then(data => {
      if (data.code !== 200) {
        vm.$message.error('获取收藏文件夹摄像机失败');
        return;
      }
      let { folder: folderId } = params;
      let { homeFolderCamera, homeFolder } = state;
      let newHomeFolderCamera = [];
      homeFolder.forEach((folder, index) => {
        if (folder.folderId === folderId) {
          // homeFolderCamera[index].cameraList = data.data;
          Vue.set(homeFolderCamera[index], 'cameraList', data.data);
        }
      });
      newHomeFolderCamera = homeFolderCamera;
      // console.log('数据', newHomeFolderCamera);
      commit('setHomeFolderCamera', newHomeFolderCamera);
      return data;
    });
  },
  //新增文件夹
  createFolderAction(store, data) {
    let objParams = {
      data,
      instructionsDel: {
        module: '重点关注',
        page: '收藏列表',
        feature: `${!data.folderId ? '新增' : '修改'}收藏夹`,
        description: `${!data.folderId ? '新增' : '修改'}收藏夹: ${
          !data.folderId
            ? data.folderName
            : '修改前：' + data.folderNameBefore + '/修改后：' + data.folderName
        }`
      }
    };
    return Vue.prototype.$api.home.createFolder(objParams).then(data => {
      if (data.code !== 200) {
        vm.$message.error('新增/修改收藏文件夹失败');
        return;
      }
      store.dispatch('getHomeDefaultCamera');
      return data.data;
    });
  },
  //删除文件夹
  deleteFolderAction(store, data) {
    let objParams = {
      data,
      instructionsDel: {
        module: '重点关注',
        page: '收藏列表',
        feature: '删除收藏夹',
        description: `删除收藏夹:  ${data.folderName}`
      }
    };
    return Vue.prototype.$api.home.deleteFolder(objParams).then(data => {
      if (data.code !== 200) {
        vm.$message.error('删除收藏文件夹失败');
        return;
      }
      vm.$message.success('删除收藏文件夹成功');
      store.dispatch('getHomeDefaultCamera');
      return data.data;
    });
  },
  //删除文件夹
  getRouteList(store, params) {
    return Vue.prototype.$api.home
        .getCongestedSection(params)
        .then((res) => {
          const { code, data } = res;
          if (code === 200) {
            let list = data?.list;
            store.commit('setBlockRouteList',list)
          } else {
            this.$message.error('获取拥堵路段失败');
          }
          return {code,data}
        })
        .catch(() => {
          this.$message.error('获取拥堵路段失败');
        });
  },
  deleteFolderCollectItem(store, params) {
    return Vue.prototype.$api.collect.deleteCollectItem(params).then(data => {
      if (data.code !== 200) {
        vm.$message.error('取消收藏失败！');
        return Promise.reject();
      }
      /* let folder = store.state.homeFolderCamera.filter(
          item => item.folderId !== params.folderId
        )[0];
        let newFolder = [...folder];
        let cameraList = newFolder.cameraList;
        if (cameraList.length > 0) {
          let newCameralList = cameraList.filter(
            item => item.cameraId !== params.cameraId
          );
          newFolder.cameraList = newCameralList;
          store.commit('setHomeFolderCamera', newFolder);
        } else {
          store.dispatch('getHomeDefaultCamera');
        } fixsxy因为要刷新数量，所以这边不能前端操作摄像机*/
      store.dispatch('getHomeDefaultCamera');
      return data;
    });
  }
};
const mutations = {
  setBlockRouteList(state,value){
    state.routeList = value;
  },
  getCamera(state, val) {
    state.getCameras = val;
  },
  setCameralListTree(state, val) {
    state.cameralListTree = val;
  },
  setCameralList(state, val) {
    state.cameralList = val;
  },
  setChartData(state, val) {
    state.chartData = val;
  },
  setChartTotalData(state, val) {
    state.chartTotalData = val;
  },
  setClassify(state, val) {
    state.classify = val;
  },
  setAmapIns(state, val) {
    state.amapIns = val;
  },
  setSelectNode(state, val) {
    state.selectNode = val;
  },
  setBotClassify(state, val) {
    state.botClassify = val;
  },
  setStatusCount(state, value) {
    state.statusCount = value;
  },
  setOnlineStatus(state, val) {
    state.onlineStatus = val;
  },
  setComType(state, val) {
    state.type = val;
  },
  setBlockList(state, val) {
    state.blockList = val;
  },
  setSplitScreenInfo(state, val) {
    state.splitScreenInfo = val;
  },
  setAddressValue(state, val) {
    state.addressValue = val;
  },
  setLat(state, val) {
    state.lat = val;
  },

  setLatResult(state, val) {
    state.latResult = val;
  },
  setAddVisible(state, val) {
    state.addVisible = val;
  },
  setLatVisible(state, val) {
    state.latVisible = val;
  },
  setSearchValue(state, val) {
    state.searchValue = val;
  },
  setSearchType(state, val) {
    state.searchType = val;
  },
  setHasFullScreen(state, val) {
    state.hasFullScreen = val;
  },
  setMaxHeight(state, val) {
    state.maxHeight = val;
  },
  setCameraInfo(state, val) {
    state.cameraInfoList = val;
  },
  setDragMove(state, val) {
    state.dragMove = val;
  },
  setClickCameraList(state, val) {
    state.clickCameraList = val;
    /* console.error('tata',state.clickCameraList,val); */
  },
  setIsAll(state, val) {
    state.isAll = val;
  },
  setDefaultZoom(state, val) {
    state.defaultZoom = val;
  },
  setSearchCameraId(state, val) {
    state.searchCameraId = val;
  },
  setSearchOrganizationId(state, val) {
    state.searchOrganizationId = val;
  },
  setProjectionVideoList(state, val) {
    state.projectionVideoList = val;
  },
  setVideoSize(state, val) {
    state.videoSize = val;
  },
  setCurrentVideoIndex(state, val) {
    state.currentVideoIndex = val;
  },
  setHomeFolder(state, val) {
    state.homeFolder = val;
  },
  setFaultDetail(state, val) {
    state.faultDetail = val;
  },
  setHomeFolderCamera(state, val) {
    state.homeFolderCamera = val;
  },
  setDrawerDom(state, value) {
    state.drawerDom = value;
  },
  setCurrentDrawerCom(state, value) {
    state.currentDrawerCom = value;
  },
  setTreeDom(state, value) {
    state.treeDom = value;
  },
  setIsSearchBtn(state, value) {
    state.isSearchBtn = value;
  },
  setNeedStopCamera(state, value) {
    state.needStopCamera = value;
  },
  setMapClick(state, value) {
    state.mapClick = value;
  },
  // 设置是否是搜索操作
  setSearchOk(state, value) {
    state.searchOk = value;
  },
  setScreenActiveIndex(state,value) {
    state.screenActiveIndex = value;
  },
  //设置哪个分屏的索引值
  setCurrentScreen(state,value){
    state.currentScreen = value;
  },
  setTreeListWd(state,value) {
    state.treeListWd = value;
  },
  setShowClassify(state,value) {
    state.isShowClassify = value;
  }
};

export default {
  state,
  mutations,
  actions
};
