import base from './base'; // 导入接口域名列表
import axios from '@/request/http'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块
import Base64 from '@/tools/base64';
import Vue from 'vue';
const aiCenterY = {
  getEventAlarm() {
    return axios.get(`${base.sq}/monitor/eventAlarm`).then(({
      data
    }) => {
      return data;
    });
  },
  modifyEventAlarm(data) {
    return axios.put(`${base.sq}/monitor/eventAlarm`, data).then(({
      data
    }) => {
      return data;
    });
  },
  getEventAlarmCamera(data) {
    return axios
      .get(
        `${base.sq}/monitor/eventAlarm/getEventAlarmCamera?${qs.stringify(
          data
        )}`
      )
      .then(({
        data
      }) => {
        return data;
      });
  },
  //折线图
  getEventAlarmStatistics(data) {
    return axios
      .post(`${base.sq}/monitor/eventAlarm/getEventAlarmStatistics`, data)
      .then(({
        data
      }) => {
        return data;
      });
  },
  //柱状图
  getEventHistogramStatistics(data) {
    return axios
      .post(`${base.sq}/monitor/eventAlarm/getEventHistogramStatistics`, data)
      .then(({
        data
      }) => {
        return data;
      });
  },
  //获取分析预案
  getAnalyse(data) {
    return axios
      .post(`${base.sq}/aiCenter/analyse/getAnalyse`, data)
      .then(({
        data
      }) => {
        return data;
      });
  },
  //导出预案列表
  exportAnalyseInfo() {
    return axios
      .get(`${base.sq}/aiCenter/analyse/exportAnalyseInfo`, {
        responseType: 'arraybuffer'
      })
      .then(({
        data
      }) => {
        return data;
      });
  },
  //路线报警统计
  getRoadwarnlist() {
    return axios
      .get(`${base.sq}/monitor/eventCq/roadwarnlist`)
      .then(({
        data
      }) => {
        return data;
      });
  },
  //获取分析中的数据
  getAnalyInfo() {
    return axios
      .get(`${base.sq}/aiCenter/analyse/getAnalyInfo`)
      .then(({
        data
      }) => {
        return data;
      });
  },
  //获取getTypeName
  getTypeName() {
    return axios
      .get(`${base.sq}/monitor/eventAlarm/getTypeName`)
      .then(({
        data
      }) => {
        return data;
      });
  },
  //获取路线报警
  /* getEventStatistics() {
    return axios
      .post(`${base.sq}/monitor/eventAlarm/getEventStatistics`)
      .then(({ data }) => {
        return data;
      });
  }, */

  //获取今日事件
  getTotayInfo() {
    return axios
      .get(`${base.sq}/monitor/eventAlarm/getTodayEventInfo`)
      .then(({
        data
      }) => {
        return data;
      });
  },
  //获取今日全部事件
  getTotayList(data) {
    return axios
      .post(`${base.sq}/monitor/eventCq/getTotayList`, data)
      .then(({
        data
      }) => {
        return data;
      });
  },
  //导出数据
  exportData(data) {
    return axios
      .get(`${base.sq}/monitor/eventCq/exportInfoList?${qs.stringify(data)}`, {
        responseType: 'arraybuffer'
      })
      .then(({
        data
      }) => {
        return data;
      });
  },
  //删除事件列表
  deleteEventList(data) {
    return axios
      .delete(
        `${base.sq}/monitor/eventCq/deleteEventList?${qs.stringify(data)}`
      )
      .then(({
        data
      }) => {
        return data;
      });
  },
  //获取路线列表
  getEventRoadInfo() {
    return axios
      .get(`${base.sq}/monitor/eventAlarm/getEventRoadInfo`)
      .then(({
        data
      }) => {
        return data;
      });
  },
  //事件合并
  updateEvent(data) {
    return axios
      .get(
        `${base.sq}/aiCenter/analyseTask/mergeEventByIds?${qs.stringify(
          data.data
        )}`, {
          headers: {
            operateNote: Base64.encode(JSON.stringify(data.instructionsDel))
          }
        }
      )
      .then(({
        data
      }) => {
        return data;
      });
  },
  //获取事件类型
  getTypeNameAll(parentId) {
    return axios
      .get(`${base.sq}/monitor/eventAlarm/getTypeName/${parentId}`)
      .then(({
        data
      }) => {
        return data;
      });
  },
  //获取今日事件时间
  getDateList() {
    return axios
      .get(`${base.sq}/monitor/eventCq/getDateList`)
      .then(({
        data
      }) => {
        return data;
      });
  },
  //获取分析方案列表
  getAnalyInfos() {
    return axios
      .get(`${base.sq}/aiCenter/analyse/getAnalyInfos`)
      .then(({
        data
      }) => {
        return data;
      });
  },
  //修改分析方案状态
  modifyAnaStatus(schemaId) {
    return axios
      .put(`${base.sq}/aiCenter/analyse/updateStatus?schemeId=${schemaId}`)
      .then(({
        data
      }) => {
        return data;
      });
  },
  //删除分析方案
  deleteAnaStatus(schemaId) {
    return axios
      .delete(`${base.sq}/aiCenter/analyse/delete?schemeId=${schemaId}`)
      .then(({
        data
      }) => {
        return data;
      });
  },
  //新增分析预案
  addAnaly(data) {
    return axios.post(`${base.sq}/aiCenter/analyse`, data).then(({
      data
    }) => {
      return data;
    });
  },
  //修改分析预案
  updateAnalyse(schemaId, data) {
    return axios
      .put(
        `${base.sq}/aiCenter/analyse/updateAnalyse?schemeId=${schemaId}`,
        data
      )
      .then(({
        data
      }) => {
        return data;
      });
  },
  //获取日历
  /* getNumByDay() {
    return axios
      .get(`${base.sq}/aiCenter/analyse/getNumByDay`)
      .then(({ data }) => {
        return data;
      });
  }, */
  //批量更新角色状态
  batchRoleStatus(data) {
    return axios.put(`${base.sq}/user/role/batch`, data).then(({
      data
    }) => {
      return data;
    });
  },
  //批量删除角色
  batchRoleData(data) {
    return axios
      .delete(`${base.sq}/user/role/batch`, {
        data: data
      })
      .then(({
        data
      }) => {
        return data;
      });
  },
  //系统设置
  setLogo(data) {
    let url = Vue.prototype.$g.isDepart ? '/user/monitorLogo' : '/user/logo';
    return axios.post(`${base.sq}${url}`, data).then(({
      data
    }) => {
      return data;
    });
  },
  //获取登录信息
  getlogoInfo() {
    return axios.get(`${base.sq}/user/monitorLogoInfo`).then(({
      data
    }) => {
      return data;
    });
  },
  //非公路树状调阅
  cameraTreeAccessOffHighway(data) {
    return axios
      .post(`${base.sq}/monitor/treeAccess/cameraTreeAccessOffHighway`, data)
      .then(({
        data
      }) => {
        return data;
      });
  },
  //非公路摄像机
  getTreeAccessOffHighwayCamera(data) {
    return axios
      .post(`${base.sq}/monitor/treeAccess/getTreeAccessOffHighwayCamera`, data)
      .then(({
        data
      }) => {
        return data;
      });
  },
  //获取拥堵收费站
  getBlockStationList() {
    return axios
      .get(`${base.sq}/monitor/roadLineBlock/getCongestionToll`)
      .then(({
        data
      }) => {
        return data;
      });
  },
  //获取异常TOP10
  getAbnormal() {
    return axios
      .get(`${base.sq}/monitor/roadLineBlock/getAbnormal`)
      .then(({
        data
      }) => {
        return data;
      });
  },
  //获取所有省份
  getRegionInfo() {
    return axios
      .get(`${base.sq}/monitor/cameraOrgTree/getRegionInfo`)
      .then(({
        data
      }) => {
        return data;
      });
  },
  //获取管理单位
  getOrganizationInfo(data) {
    return axios
      .get(
        `${base.sq}/monitor/cameraOrgTree/getOrganizationInfo?${qs.stringify(
          data
        )}`
      )
      .then(({
        data
      }) => {
        return data;
      });
  },
  //部平台定制管辖单位接口
  getNetWorkByOrg(data) {
    return axios
      .post(`${base.sq}/monitor/cameraOrgTree/getNetWorkByOrg`, data)
      .then(({
        data
      }) => {
        return data;
      });
  },
  //记录摄像机历史
  setCameraHistory(cameraId) {
    return axios
      .post(`${base.sq}/monitor/cameraHistory/${cameraId}`)
      .then(({
        data
      }) => {
        return data;
      });
  },
  //获取摄像机历史GET /monitor/cameraHistory
  getCameraHistory() {
    return axios.get(`${base.sq}/monitor/cameraHistory`).then(({
      data
    }) => {
      return data;
    });
  },
  //删除摄像机历史
  deleteCameraHistory(data) {
    return axios
      .delete(`${base.sq}/monitor/cameraHistory`, {
        headers: {
          operateNote: Base64.encode(JSON.stringify(data.instructionsDel))
        },
        data: data.params
      })
      .then(({
        data
      }) => {
        return data;
      });
  },
  //部级平台获取区域
  getRegion() {
    return axios
      .get(`${base.sq}/monitor/organizationOnline/getRegion`)
      .then(({
        data
      }) => {
        return data;
      });
  },
  //摄像机导出/device/cameras/download/excel-detail/all
  exportCameraAll(params) {
    return axios
      .get(
        `${base.sq}/device/cameras/download/excel-detail/all?${qs.stringify(
          params.data
        )}`, {
          responseType: 'arraybuffer',
          headers: {
            operateNote: Base64.encode(JSON.stringify(params.instructionsDel))
          }
        }
      )
      .then(({
        data
      }) => {
        return data;
      });
  },

  //获取ai中心分析方案列表
  getAnalysePLanList(data) {
    return axios
      .post(`${base.sq}/aiCenter/analyseTask/getAnalysePLanList`, data)
      .then(({
        data
      }) => {
        return data;
      });
  },
  getAnalyseExecuteTaskList(data) {
    return axios
      .post(`${base.sq}/aiCenter/analyseTask/getAnalyseExecuteTaskList`, data)
      .then(({
        data
      }) => {
        return data;
      });
  },
  addTask(data) {
    return axios
      .post(`${base.sq}/aiCenter/analyseTask/addTask`, data.data, {
        headers: {
          operateNote: Base64.encode(JSON.stringify(data.instructionsDel))
        }
      })
      .then(({
        data
      }) => {
        return data;
      });
  },
  deleteTask(planId) {
    return axios
      .delete(`${base.sq}/aiCenter/analyseTask/deleteTask?planId=${planId}`)
      .then(({
        data
      }) => {
        return data;
      });
  },
  queryCameraEventByTaskId(taskId) {
    return axios
      .get(
        `${base.sq}/aiCenter/analyseTask/queryCameraEventByTaskId?taskId=${taskId}`
      )
      .then(({
        data
      }) => {
        return data;
      });
  },
  queryEventTypeListByTaskId(taskId) {
    return axios
      .get(
        `${base.sq}/aiCenter/analyseTask/queryEventTypeListByTaskId?taskId=${taskId}`
      )
      .then(({
        data
      }) => {
        return data;
      });
  },
  exportAnalysePlanInfo(data) {
    return axios
      .get(
        `${base.sq}/aiCenter/analyseTask/exportAnalysePlanInfo?${qs.stringify(
          data
        )}`, {
          responseType: 'arraybuffer'
        }
      )
      .then(({
        data
      }) => {
        return data;
      });
  },
  startOrStopPlan(data) {
    return axios
      .get(
        `${base.sq}/aiCenter/analyseTask/startOrStopPlan?${qs.stringify(data)}`
      )
      .then(({
        data
      }) => {
        return data;
      });
  },
  //获取路段流量top10
  getTrafficVolume() {
    return axios
      .get(`${base.sq}/monitor/roadLineBlock/getTrafficVolume`)
      .then(({
        data
      }) => {
        return data;
      });
  },
  //获取事件统计数据临时接口
  getEventInfoByDay(data) {
    return axios
      .get(
        `${base.sq}/monitor/eventAlarm/getEventInfoByDay/${
          data.timeType
        }?${qs.stringify(data.url)}`
      )
      .then(({
        data
      }) => {
        return data;
      });
  },
  //获取分析中的预案
  queryExecutingPlan() {
    return axios
      .get(`${base.sq}/aiCenter/analyseTask/queryExecutingPlan`)
      .then(({
        data
      }) => {
        return data;
      });
  },
  //获取分析中的摄像机
  queryCamerasByPlanId(planId) {
    return axios
      .get(
        `${base.sq}/aiCenter/analyseTask/queryCamerasByPlanId?planId=${planId}`
      )
      .then(({
        data
      }) => {
        return data;
      });
  },
  //查询地图上有事件的摄像机列表
  queryCamerasOnMap() {
    return axios
      .get(`${base.sq}/aiCenter/analyseTask/queryCamerasOnMap`)
      .then(({
        data
      }) => {
        return data;
      });
  },
  //查询地图上报警点位
  queryAiAnalysep() {
    return axios
      .get(`${base.sq}/monitor/aiAnalyse/list`)
      .then(({
        data
      }) => {
        return data;
      });
  },
  //今日事件统计
  todayEventStatistics() {
    return axios
      .get(`${base.sq}/aiCenter/analyseTask/todayEventStatistics`)
      .then(({
        data
      }) => {
        return data;
      });
  },
  //查询最近的事件报告
  queryNewestList() {
    return axios
      .get(`${base.sq}/aiCenter/analyseTask/queryNewestList`)
      .then(({
        data
      }) => {
        return data;
      });
  },
  //查询当天所有事件列表
  queryEventByCondition(data) {
    return axios
      .post(`${base.sq}/aiCenter/analyseTask/queryEventByCondition`, data)
      .then(({
        data
      }) => {
        return data;
      });
  },
  // 查询事件结果
  queryEventResultListByCondition(data) {
    return axios
      .post(
        `${base.sq}/aiCenter/analyseTask/queryEventResultListByCondition`,
        data
      )
      .then(({
        data
      }) => {
        return data;
      });
  },
  //获取日历事件接口
  getNumByDay(data) {
    return axios
      .get(`${base.sq}/aiCenter/analyse/getNumByDay?${qs.stringify(data)}`)
      .then(({
        data
      }) => {
        return data;
      });
  },
  //导出Ai事件结果集
  exportEventInfo(data) {
    return axios
      .get(
        `${base.sq}/aiCenter/analyseTask/exportEventInfo?${qs.stringify(
          data.data
        )}`, {
          responseType: 'arraybuffer',
          headers: {
            operateNote: Base64.encode(JSON.stringify(data.instructionsDel))
          }
        }
      )
      .then(({
        data
      }) => {
        return data;
      });
  },
  //获取事件字典
  getAllEvents() {
    return axios
      .get(`${base.sq}/aiCenter/analyseTask/getAllEvents`)
      .then(({
        data
      }) => {
        return data;
      });
  },
  //路线报警统计
  getEventStatistics() {
    return axios
      .post(`${base.sq}/monitor/eventAlarm/getEventStatistics`)
      .then(({
        data
      }) => {
        return data;
      });
  },
  //获取事件详情
  getEventStatisticsInfo(data) {
    return axios
      .post(`${base.sq}/monitor/eventAlarm/getEventStatisticsInfo`, data)
      .then(({
        data
      }) => {
        return data;
      });
  },
  //误报
  misinformation(data) {
    return axios
      .delete(
        `${base.sq}/monitor/eventAlarm/misinformation?${qs.stringify(
          data.data
        )}`, {
          headers: {
            operateNote: Base64.encode(JSON.stringify(data.instructionsDel))
          }
        }
      )
      .then(({
        data
      }) => {
        return data;
      });
  }
};

export default aiCenterY;