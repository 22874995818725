<template>
  <div class="evidence-video-main">
    <div class="video-play">
      <!-- 播放器 -->
      <template>
        <template>
          <div class="video-evidence-details flex" id="video-details">
            <img
              src="~@/assets/AI/left-btn.png"
              alt="左侧按钮"
              @click="leftClick()"
              class="left-btn"
            />
            <img
              src="~@/assets/AI/right-btn.png"
              alt="右侧按钮"
              @click="rightClick()"
              class="right-btn"
            />
            <!-- {{dataList}} -->
            <div v-for="(i, index) in dataList" :key="index">
              <div
                @click="selectVideo(i, index)"
                :class="{ active: index === selectedItemIndex }"
                class="evidence-play-box"
              >
                <flv-player2
                  :ref="'videoPlay' + index"
                  :indexs="index"
                  :urls="i"
                  :control="false"
                  type="mp4"
                ></flv-player2>
              </div>
            </div>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>
<script>
import FlvPlayer2 from "@/components/module/camera/FlvPlayer2";
export default {
  props: ["dataList", "camearId"],
  name: "circumVideo",
  components: {
    FlvPlayer2,
  },
  data() {
    return {
      uuid: null,
      selectedItemIndex: -1,
    };
  },
  computed: {},
  watch: {
    camearId: {
      handler() {
        this.getUrl();
      },
      deep: true,
    },
  },
  mounted() {
    // this.getUrl(urlList);
  },
  methods: {
    // 获取播放地址
    getUrl(urlList) {
      console.log("urlList", urlList);
      let that = this,
        obj = {};
      urlList.map((item, index) => {
        console.log("item.url", item.url, index, obj);
        // this.$nextTick(() => {
        this.$refs[`videoPlay${index}`] &&
          this.$refs[`videoPlay${index}`][0].flv_Play(item.url, obj);
        // });
      });
      that.$forceUpdate();
      that.getUrlLoading = false;
    },
    // 选择摄像机
    selectVideo(val, index) {
      console.log("val", val);
      this.$emit("getVideoUrl", val);
      this.selectedItemIndex = index;
    },
    leftClick() {
      const gallery = document.querySelector("#video-details");
      gallery.scrollBy(-175, 0);
    },
    rightClick() {
      const gallery = document.querySelector("#video-details");
      gallery.scrollBy(175, 0);
    },
  },
  destroyed() {},
};
</script>
<style lang="less" scoped>
.evidence-play-box {
  width: 175px;
  height: 98px;
  margin: 0 5px;
  background: #0e2132;
}
.video-evidence-details {
  overflow: hidden;
  display: flex;
  width: 560px;
  padding: 0 2px;
  img {
    width: 18px;
    height: 138px;
  }
  .left-btn {
    position: absolute;
    top: 0;
    left: -10px;
  }
  .right-btn {
    position: absolute;
    top: 0;
    right: 2px;
  }
}
.video-play {
  padding: 0 2px;
}
.active {
  border: 2px solid rgba(212, 34, 10);
}
.evidence-video-main {
  padding: 20px 0;
}
// .evidence-video-main {
//   width: 145px;
//   height: 98px;
//   margin: 0 4px;
//   background: #0e2132;
//   .video-play {
//     position: relative;
//     width: 100%;
//     height: 100%;
//     padding: 1px;
//     // border: 1px solid #3f80b0;
//     .offline-box {
//       height: 100%;
//       display: flex;
//       flex-direction: column;
//       justify-content: center;
//       align-items: center;
//       background: #151515;
//       .img-offline {
//         width: 226px;
//         height: 47px;
//       }
//       .offline-tip {
//         opacity: 0.6;
//         font-size: 18px;
//         font-family: Source Han Sans CN, Source Han Sans CN-Regular;
//         font-weight: 400;
//         text-align: left;
//         color: #ffffff;
//         line-height: 24px;
//         margin-top: 28px;
//         span {
//           font-size: 18px;
//           font-weight: 500;
//           color: #fffefe;
//         }
//         i {
//           display: inline-block;
//           font-style: normal;
//           font-weight: 400;
//           margin-left: 10px;
//           width: 45px;
//           height: 28px;
//           line-height: 28px;
//           font-size: 18px;
//           text-align: center;
//           border: 1px solid #fff;
//           border-radius: 6px;
//           cursor: pointer;
//         }
//       }
//       &:hover {
//         .show {
//           display: flex;
//           cursor: move;
//         }
//         .show2 {
//           display: flex;
//           cursor: move;
//         }
//       }
//     }
//     .player-fail {
//       position: absolute;
//       top: 0;
//       width: 100%;
//       height: 100%;
//       display: flex;
//       flex-direction: column;
//       justify-content: center;
//       align-items: center;
//       background: #000000;
//       .fail-box {
//         position: relative;
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//         align-items: center;
//         width: 100%;
//         height: 114px;
//         .fail-info {
//           img {
//             display: inline-block;
//             width: 37px;
//             height: 47px;
//             // background: url(~@/assets/images/map-monitor/player/fail.png) 0 0 /
//             //   100% 100% no-repeat;
//           }
//           .info {
//             display: inline-block;
//             height: 40px;
//             line-height: 40px;
//             margin-left: 25px;
//             vertical-align: middle;
//             span {
//               display: block;
//               height: 20px;
//               line-height: 20px;
//               font-size: 16px;
//               font-family: Source Han Sans CN, Source Han Sans CN-Regular;
//               font-weight: 400;
//               text-align: left;
//               color: #ffffff;
//             }
//           }
//         }
//         &.fail-box::before {
//           content: '';
//           position: absolute;
//           top: 0;
//           left: 50%;
//           transform: translateX(-50%);
//           width: 60%;
//           height: 6px;
//           // background: url(~@/assets/images/map-monitor/player/side.png) 0 0 /
//           //   100% 100% no-repeat;
//         }
//         &.fail-box::after {
//           content: '';
//           position: absolute;
//           bottom: 0;
//           left: 50%;
//           transform: translateX(-50%);
//           width: 60%;
//           height: 6px;
//           // background: url(~@/assets/images/map-monitor/player/side.png) 0 0 /
//           //   100% 100% no-repeat;
//         }
//       }
//       .contact {
//         padding-left: 62px;
//         margin-top: 10px;
//         color: #999999;
//       }
//     }
//     .video-evidence-details {
//       position: relative;
//       .video-top {
//         // display: none;
//         display: flex;
//         position: absolute;
//         top: 0;
//         left: 0;
//         width: 100%;
//         height: 34px;
//         // filter: blur(1px);
//         align-items: center;
//         z-index: 9;
//         overflow: hidden;
//         .camera-info {
//           background: rgba(6, 25, 47, 0.3);
//           display: none;
//           padding: 10px;
//           width: 100%;
//           justify-content: center;
//           align-items: center;
//           font-size: 12px;
//           font-family: Source Han Sans CN, Source Han Sans CN-400;
//           font-weight: 400;
//           color: #ffffff;
//         }
//       }
//       .video-bottom {
//         display: none;
//         // display: flex;
//         position: absolute;
//         bottom: 0;
//         left: 0;
//         width: 100%;
//         height: 28px;
//         background: rgba(6, 25, 47, 0.3);
//         align-items: center;
//         justify-content: space-between;
//         z-index: 19;
//         .camera-tripod {
//           display: flex;
//           justify-content: center;
//           align-items: center;
//           width: 20px;
//           height: 20px;
//         }
//         /deep/.resolution-all {
//           width: 43px !important;
//           height: 20px !important;
//           font-size: 14px !important;
//         }
//         /deep/.ant-select-selection--single {
//           height: 22px !important;
//           line-height: 20px;
//         }
//       }
//       .box-tripod {
//         display: none;
//         // display: flex;
//         position: absolute;
//         top: 0;
//         left: 0;
//         width: 100%;
//         height: calc(100% - 28px);
//         // background: pink;
//         // filter: blur(1px);
//         justify-content: flex-end;
//         align-items: center;
//         z-index: 9;
//       }
//       .but {
//         display: inline-block;
//         cursor: pointer;
//       }
//       .cloud {
//         width: 20px;
//         height: 21px;
//         background: url('~@/assets/dialog/cloud-btn.png') no-repeat;
//         background-size: 100% 100%;
//         // background: url(~@/assets/images/map-monitor/player/cloud-off.png) 0 0 /
//         //   100% 100% no-repeat;
//         // &.active {
//         //   background: url(~@/assets/images/map-monitor/player/cloud-on.png) 0 0 /
//         //     100% 100% no-repeat;
//         // }
//       }
//       .shortcut-btn {
//         width: 14px;
//         height: 14px;
//         background: url('~@/assets/dialog/shortcut-btn.png') no-repeat;
//         background-size: 100% 100%;
//       }
//       &:hover {
//         .show {
//           display: flex;
//         }
//         .show2 {
//           display: block;
//         }
//       }
//     }
//     .player {
//       width: 100%;
//       height: 100%;
//       object-fit: fill;
//     }
//   }
// }
// /deep/.ant-select-dropdown {
//   top: auto !important;
//   bottom: 0;
// }
</style>
