import { render, staticRenderFns } from "./EventAlarmDetail.vue?vue&type=template&id=a979fb72&scoped=true&"
import script from "./EventAlarmDetail.vue?vue&type=script&lang=js&"
export * from "./EventAlarmDetail.vue?vue&type=script&lang=js&"
import style0 from "./EventAlarmDetail.vue?vue&type=style&index=0&id=a979fb72&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a979fb72",
  null
  
)

export default component.exports